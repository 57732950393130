.el-form /deep/ {
  .lessMarginBottom .el-form-item {
    margin-bottom: 20px;
  }

  .el-col {
    @include rtl-sass-value(float, left, right);
  }

  label {
    color: $primary-green;
  }

  .phone,
  .date_birth {
    position: relative;

    .labelTitle {
      position: absolute;
      top: -14px;
    }

    .row {
      display: grid;
    }

    .el-form-item__error {
      width: 300%;
    }
  }

  .phone .row {
    grid-template-columns: 25% 65%;
    column-gap: calc(100% - 90%);
  }

  .date_birth .row {
    grid-template-columns: 31% 31% 31%;
    column-gap: calc((100% - 93%) / 2);
  }

  .vRadio {
    margin-top: 55px;

    .el-form-item__label {
      bottom: 45px;
      font-size: 14px;
      color: $primary-purple;
    }

    .el-radio {
      color: $primary-purple;
    }

    .el-radio__input.is-checked+.el-radio__label {
      color: $primary-purple;
    }
  }

  .el-select .el-input__inner[disabled='disabled'] {
    background: none;
  }
}

@media (max-width: 550px) {
  .el-form /deep/ {
    .lessMarginBottom .el-form-item {
      margin-bottom: 40px;
    }

    .phone .row {
      grid-template-columns: 35% 60%;
    }

    .vRadio {
      .el-form-item__label {
        bottom: -18px;
        margin-bottom: 57px;
      }

      .el-form-item__content {
        margin-top: 20px;
      }
    }
  }
}